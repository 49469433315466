
<template>
  <v-scale-transition>
    <wide-panel
      :title="title"
      :breadcrumbs="breadcrumbs"
    >
      <v-toolbar
        card
        extended
      >
        <v-container fluid>
          <v-layout row>
            <v-flex xs4>
              <v-select
                v-model="filter"
                :items="filters"
                item-text="text"
                item-value="value"
                label="Filter by"
              />
            </v-flex>
            <v-flex xs6>
              <v-select
                v-if="filter === 'stat'"
                v-model="stat"
                :items="status"
                item-text="text"
                item-value="value"
                label="Select status"
              />
              <v-text-field
                v-if="filter !== null && filter !== 'stat'"
                v-model="search"
                label="Enter search string"
                clearable
              />
            </v-flex>
            <v-flex xs3>
              <v-menu
                v-if="filter === 'stat'"
                ref="menu"
                v-model="menu"
                lazy
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="day"
                  prepend-icon="event"
                  label="Select date"
                  readonly
                />
                <v-date-picker
                  ref="picker"
                  v-model="day"
                  :show-current="false"
                  actions
                  no-title
                  @change="saveMenu"
                />
              </v-menu>
            </v-flex>
            <v-card-actions>
              <v-spacer />
              <v-btn
                v-if="filter !== null"
                color="primary"
                dark
                class="mb-2"
                @click="refreshList()"
              >
                Search
              </v-btn>
            </v-card-actions>
            <v-flex xs1>
              <v-spacer />
              <v-checkbox
                v-model="xox"
                label="XOX"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :rows-per-page-items="[10, 25, 50, 100]"
            :expand="true"
            item-key="lastUpdate"
            class="elevation-1"
            disable-initial-sort
          >
            <template #items="props">
              <tr>
                <td
                  v-if="props.item.dealer"
                  class="text-xs-left"
                >
                  {{ props.item.dealer }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.name"
                  class="text-xs-left"
                >
                  {{ props.item.name }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.donor"
                  class="text-xs-left"
                >
                  {{ props.item.donor }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.msisdn"
                  class="text-xs-left"
                >
                  {{ props.item.msisdn }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.iccid"
                  class="text-xs-left"
                >
                  {{ props.item.iccid }}
                </td>
                <td
                  v-if="props.item.subscriberName"
                  class="text-xs-left"
                >
                  {{ props.item.subscriberName }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.nric"
                  class="text-xs-left"
                >
                  {{ props.item.nric }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.plan"
                  class="text-xs-center"
                >
                  {{ props.item.plan }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>

                <td
                  v-if="props.item.status === 2"
                  class="text-xs-center"
                >
                  Pending /{{ props.item.statusmsg }} / {{ props.item.rejectcode }}
                </td>
                <td
                  v-if="props.item.status === 5"
                  class="text-xs-center"
                >
                  Rejected /{{ props.item.statusmsg }} / {{ props.item.rejectcode }}
                </td>
                <td
                  v-if="props.item.status === 7"
                  class="text-xs-center"
                >
                  Cancelled /{{ props.item.statusmsg }} / {{ props.item.rejectcode }}
                </td>
                <td
                  v-if="props.item.status === 9"
                  class="text-xs-center"
                >
                  Activated  /{{ props.item.statusmsg }} / {{ props.item.rejectcode }}
                </td>
                <td
                  v-if="props.item.status === '2' && props.item.statusmsg === ''"
                  class="text-xs-center"
                >
                  Activated  /{{ props.item.statusmsg }} / {{ props.item.rejectcode }}
                </td>
                <td
                  v-if="props.item.dateCreate"
                  class="text-xs-center"
                >
                  {{ props.item.dateCreate }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.lastUpdate"
                  class="text-xs-center"
                >
                  {{ props.item.lastUpdate }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
              </tr>
            </template>
            <template slot="no-data">
              <v-alert
                :value="true"
                :type="error ? 'error' : 'info'"
              >
                {{ noDataText }}
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </wide-panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams } from '@/rest'
import security from '@/security'
import { DateTime } from 'luxon'
// import Jimp from 'jimp'

const pageTitle = 'Mnp Status'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Mnp', disabled: false, to: '/support/mnp',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      activeItem: null,
      alert: false,
      alertType: null,
      alertMessage: null,
      maxDate: '',
      xox: null,
      error: null,
      menu: false,
      day: null,
      filter: null,
      filters: [
        {
          text: 'Msisdn',
          value: 'msisdn',
        },
        {
          text: 'ICCID',
          value: 'iccid',
        },
        {
          text: 'Dealer',
          value: 'dealer',
        },
        {
          text: 'Status',
          value: 'stat',
        },
      ],
      stat: null,
      status: [
        {
          text: 'Pending',
          value: '2',
        },
        {
          text: 'Cancelled',
          value: '7',
        },
        {
          text: 'Success',
          value: '9',
        },
        {
          text: 'Rejected',
          value: '5',
        },
        {
          text: 'Pending Ack',
          value: '1',
        },
      ],
      headers: [
        {
          align: 'left',
          sortable: true,
          text: 'Dealer ID',
          value: 'dealer',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Dealer Name',
          value: 'name',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Donor',
          value: 'donor',
        },
        {
          align: 'left',
          sortable: true,
          text: 'MSISDN',
          value: 'msisdn',
        },
        {
          align: 'left',
          sortable: true,
          text: 'ICCID',
          value: 'iccid',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Subscriber Name',
          value: 'subscriberName',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Nric',
          value: 'nric',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Plan',
          value: 'plan',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Status',
          value: 'status',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Apply Date',
          value: 'dateCreate',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Last Update',
          value: 'lastUpdate',
        },
      ],
      items: [],
      onex: true,
      loading: false,
      snackbar: false,
      snackbarMessage: null,
      search: null,
      searchId: null,
      source: null,
      title: pageTitle,
    }
  },
  computed: {
    noDataText: function () {
      return this.error ? this.error : "There's nothing to display."
    },
  },
  watch: {
    xox: function (val) {
      this.onex = !val
    },
    filter: function () {
      (this.search || this.status || this.day) && this.resetList()
      this.search = null
      this.stat = null
      this.day = null
    },

  },
  mounted: function () {
    this.xox = security.me.claimer() === 'xox'
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.day = now.toFormat('yyyy-LL-dd')
  },
  methods: {
    async getUserList () {
      const filters = this.getFilters()
      if (!filters) return { items: [] }
      this.loading = true
      const parameters = {}
      Object.assign(parameters, filters)

      const apiParameters = createGetParams(parameters)
      try {
        this.source = this.$rest.CancelToken.source()
        Object.assign(apiParameters, { cancelToken: this.source.token })
        const response = await this.$rest.get('getMnpRequest.php', apiParameters)
        this.error = null
        this.loading = false
        return response.data
      } catch (error) {
        if (!this.$rest.isCancel(error)) {
          this.error = error.message
        }
        return { items: [] }
      }
    },
    getFilters () {
      const filterBy = {}

      if (this.filter === 'msisdn' && this.search) {
        Object.assign(filterBy, { msisdn: this.search })
      }

      if (this.filter === 'iccid' && this.search) {
        Object.assign(filterBy, { iccid: this.search })
      }
      if (this.filter === 'dealer' && this.search) {
        Object.assign(filterBy, { dealer: this.search })
      }
      if (this.filter === 'stat' && this.stat) {
        Object.assign(filterBy, { stat: this.stat })
      }

      Object.assign(filterBy, { day: this.day })
      Object.assign(filterBy, { onex: this.onex })

      // return this.filter && (this.search || this.stat) ? filterBy : null
      return this.filter && (this.search || this.stat || this.day) ? filterBy : null
    },
    cancelRequest: function () {
      if (this.source) {
        this.source.cancel('Forced cancellation.')
        this.source = null
      }
    },
    refreshList () {
      this.getUserList().then(data => {
        this.items = data.items
      })
    },
    resetList () {
      this.items = []
    },
    saveMenu (date) {
      this.$refs.menu.save(date)
    },
    showError (message) {
      this.alertType = 'error'
      this.alertMessage = message
      this.alert = true
    },
  },
}
</script>
